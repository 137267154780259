<template>
  <section class="footer">
    <div class="footer__devider">
      <div class="container">
        <div class="footer__picture">
          <router-link to="/">
            <img @click="scrollToTop" class="footer__img" src="../../assets/images/beginning.png" alt="beginning">
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex-wrap container d-flex justify-space-between">
      <div class="footer__social footer__item d-flex flex-column">
        <span class="footer__feedback align-se">{{$vuetify.lang.t("$vuetify.main.footer.question")}} <br>{{$vuetify.lang.t("$vuetify.main.footer.contactUs")}} </span>
        <a class="footer__phone" href="tel:+380673077887">
          <img class="footer__phone-img" src="../../assets/images/6_icon_tel.png" alt="6_icon_tel">
          <span class="footer__phone-text">+38-067-307-78-87</span>
        </a>
        <div class="footer__links">
          <a href="https://www.facebook.com/%D0%9F%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B0-110554091618488" target="_blank">
            <img class="footer__social-item" src="../../assets/images/6_icon_fb.png" alt="6_icon_fb">
          </a>
          <a href="https://instagram.com/platforma.life?utm_medium=copy_link" target="_blank">
            <img class="footer__social-item" src="../../assets/images/6_icon_insta.png" alt="6_icon_insta">
          </a>
          <a href="https://t.me/+Dqd5L573QkpjMWFi" target="_blank">
            <img class="footer__social-item" src="../../assets/images/6_icon_Telega.png" alt="6_icon_Telega">
          </a>
          <a href="https://wa.me/380673077887" target="_blank">
            <img class="footer__social-item" src="../../assets/images/6_icon_VS.png" alt="6_icon_VS">
          </a>
        </div>
        <span class="footer__copyright">&copy; {{ new Date().getFullYear() }} Платформа</span>
      </div>
      <div class="footer__description footer__item">
        <p class="text-start">
          {{
            $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementPart1")
          }}
          <terms>{{
              $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementLink")
            }}</terms>
          <br>
          <span class="text--secondary">{{
              $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementPart2")
            }}</span>
          <personal-data>{{
              $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementLink2")
            }}</personal-data>
          <br />
          <br />
          <span>{{ $vuetify.lang.t("$vuetify.main.footer.legal.terms") }}</span>
          <br />
          <br />
          <span>{{
              $vuetify.lang.t("$vuetify.main.footer.legal.protected")
            }}</span>
        </p>
      </div>
      <div class="footer__faq footer__item d-flex flex-column justify-space-between">
        <div class="footer__info d-flex flex-column align-end">
          <router-link class="footer__info-item" to="/faq">
            {{$vuetify.lang.t("$vuetify.main.footer.footerMenu.FAQ") }}
          </router-link>
          <router-link class="footer__info-item" color="primary" to="/about">
            <div @click="scrollToTop">{{$vuetify.lang.t("$vuetify.main.footer.footerMenu.aboutProject")}}</div>
          </router-link>
          <router-link class="footer__info-item" to="/contacts">
            {{$vuetify.lang.t("$vuetify.main.footer.footerMenu.Contacts")}}
          </router-link>
        </div>
        <v-select
          class="footer__language-btn"
          v-model="currentLanguage"
          @change="changeLocale"
          :items="languages"
          item-text="state"
          item-value="lang"
          background-color="#2fa3b5"
          solo
        ></v-select>
      </div>
    </div>
  </section>
</template>

<script>
const PersonalData = () => import('../../components/UI/personalTerms/PersonalData')
const Terms = () => import('../../components/UI/personalTerms/Terms')
export default {
  name: 'FooterComponent',
  components: { PersonalData, Terms },
  data () {
    return {
      currentLanguage: { state: 'Українська', lang: 'uk' },
      languages: [
        { state: 'Українська', lang: 'uk' },
        { state: 'Русский', lang: 'ru' }
      ]
    }
  },
  created () {
    this.currentLanguage = this.$store.getters['app/getSelectedLocale'] === 'ru'
      ? { state: 'Русский', lang: 'ru' }
      : { state: 'Українська', lang: 'uk' }
  },
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = this.currentLanguage
      this.$store.dispatch('app/changeLocale', this.currentLanguage)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="scss">
.footer__language-btn .v-input__control {
  margin-top: auto;
}
.footer__language-btn .v-select__selection.v-select__selection--comma {
  color: white;
}
</style>
<style lang="scss" scoped>
.footer {
  &__devider {
    margin-bottom: 20px;
    background-color: #fff;
    border-top: 2px solid $lightColor;
    border-bottom: 2px solid $lightColor;
    padding: 15px 0;
  }
  &__picture {
    display: flex;
    justify-content: start;
  }
  &__img {
    max-width: 240px;
    height: auto;
  }
  &__item {
    max-width: 340px;
  }
  &__faq {
    margin-left: auto;
  }
  &__social span {
    font-weight: 400;
  }
  &__feedback {
    font-size: 1rem;
    font-weight: 300;
    color: $darkTextColor;
  }
  &__phone {
    font-size: 1.1rem;
    text-decoration: none;
    margin-top: 25px;
  }
  &__phone-text {
    color: $darkTextColor;
    font-size: 1.2rem;
    font-weight: 300;
  }
  &__links {
    display: flex;
    gap: 5px;
    margin-top: 50px;
  }
  &__social-item {
    max-width: 35px;
  }
  &__phone-img {
    max-width: 15px;
    height: auto;
  }
  &__phone-text {
    font-size: 1rem;
  }
  &__copyright {
    font-size: 1rem;
    display: block;
    margin-top: 50px;
  }

  &__description {
    color: $darkTextColor;
    margin: 0 auto;
    & p {
      font-weight: normal;
      font-size: 0.8rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 1px;
    min-width: 180px;
  }
  &__info-item {
    text-decoration: none;
    font-weight: normal;
    font-size: 1rem;
  }
  &__language-btn {
    max-width: 180px;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    outline: none;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .justify-xs-center {
    justify-content: center !important;
  }
}
@media (max-width: 565px) {
  .footer__description {
    max-width: 165px;
  }
}
</style>
